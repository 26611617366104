import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from "./components/NavBar";
import NochillioEditor from './components/NochillioEditor';
import LandingPage from './components/LandingPage';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './App.css';

function App() {
  return (
    <Router>
      <DndProvider backend={HTML5Backend}>
        <div className="App">
          <NavBar />
          <div className="main-content">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/pimpmywide" element={<NochillioEditor />} />
            </Routes>
          </div>
        </div>
      </DndProvider>
    </Router>
  );
}

export default App;
